<template>
	<div class="single-view tool-base-encoder">
		<div class="tool-box">
			<h1>{{ ii('BASE64_ENCODER_DECODER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-area
						v-model="text"
						ref="tf"
						:placeholder="ii('ENTER_PLAIN_TEXT_HERE')"
						:error="textError"
						@input="onTextChange"
					></s-text-area>

					<s-text-area
						v-model="base64"
						ref="bf"
						:placeholder="ii('ENTER_BASE64_DATA_HERE')"
						:error="base64Error"
						@input="onBase64Change"
					></s-text-area>
				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
	.tool-base-encoder {
		.tool-input-box {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			max-width: 700px !important;

			.text-area {
				margin: 5px;
				max-width: 300px;
				min-width: 200px;

				textarea {
					resize: none;
				}
			}
		}
	}
</style>

<script>
	export default {
		data() {
			return {
				text: '',
				base64: '',
				textError: null,
				base64Error: null,
			};
		},
		methods: {
			base64Encode() {
				this.base64Error = null;

				try {
					this.textError = null;
					this.base64 = btoa(this.text || '');
				} catch (e) {
					this.base64 = '';
					this.textError = 'Invalid text';
				}
			},
			base64Decode() {
				this.textError = null;

				try {
					this.base64Error = null;
					this.text = atob(this.base64 || '');
				} catch (e) {
					this.text = '';
					this.base64Error = 'Invalid Base64 data';
				}
			},
			onTextChange() {
				clearTimeout(this.debounce);
				this.debounce = setTimeout(() => {
					this.base64Encode();
					this.app.query.text = this.text.length < 1000 ? this.text : '';
					this.app.query.base64 = '';
					this.refreshQuery();
					this.$nextTick(() => {
						this.$refs.tf.focus();
					});
				}, 300);
			},
			onBase64Change() {
				clearTimeout(this.debounce);
				this.debounce = setTimeout(() => {
					this.base64Decode();
					this.app.query.base64 = this.base64.length < 1000 ? this.base64 : '';
					this.app.query.text = '';
					this.refreshQuery();
					this.$nextTick(() => {
						this.$refs.bf.focus();
					});
				}, 300);
			},
		},
		mounted() {
			if (this.app.query.text) {
				this.text = this.app.query.text;
				this.onTextChange();
			} else if (this.app.query.base64) {
				this.base64 = this.app.query.base64;
				this.onBase64Change();
			} else {
				this.$refs.tf.focus();
			}
		}
	}
</script>